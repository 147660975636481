@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Hide delete button by default */
.item-container .hide-button {
  display: none;
}

/* Show delete button when task is hovered over */
.item-container:hover .hide-button {
  display: inline;
}

.item-container {
  @apply mt-2;
  background-color: rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
  /* Smooth transition for all properties */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  /* Add a soft shadow */
  border-radius: 3px;
  /* Rounded corners */
  padding-left: 12px;
}

@keyframes highlight {
  0% {
    background-color: #a4c6e465;
    /* Starting color */
    transform: scale(1.02);
    /* Slightly enlarging the task */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Increase shadow */
  }

  100% {
    background-color: transparent;
    /* Final state */
    transform: scale(1);
    /* Return to normal size */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Return to normal shadow */
  }
}

.highlight-task {
  animation: highlight 1s ease-out;
  /* 1-second animation */
}

.selected-item {
  background-color: #34498693;
}

.slide-in {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.slide-in.open {
  max-height: 4rem; /* Match the height of the input */
}

/* Add this to your CSS file */
.completed-task-container {
  display: flex;
  align-items: center;
  position: relative;
}

/* In your CSS file */
.completed-task-container:hover .uncomplete-checkbox {
  opacity: 1;
}

.uncomplete-checkbox {
  opacity: 0;
  transition: opacity 0.3s;
}

/* theme.css */
.theme-bg-darker {
  @apply dark:bg-gray-900;
}

.theme-bg-dark {
  @apply dark:bg-gray-800;
}

.theme-text-dark {
  @apply dark:text-white;
}

.padding-small {
  @apply p-2;
}

.padding-medium {
  @apply p-4;
}

.padding-large {
  @apply p-6;
}

.margin-small {
  @apply m-2;
}

.margin-bottom-small {
  @apply mb-2;
}

.margin-right-small {
  @apply mr-2;
}

.margin-top-medium {
  @apply mt-4;
}

.border-radius-medium {
  @apply rounded-md;  
}

.border-radius-large {
  @apply rounded-lg;
}

.tab-selected-dark {
  @apply bg-gray-600;
}

.theme-tab-list-bg {
  @apply bg-gray-700;
}

.input-theme {
  @apply p-2 rounded-md bg-white dark:text-white dark:bg-gray-700 margin-small;
}

.dropdown-theme {
  @apply p-2 rounded-md bg-white dark:text-white dark:bg-gray-700 margin-small;
}

.input-group {
  display: flex;
  align-items: center;
}

.input-theme::placeholder {
  color: #777777b2; /* You can adjust this value to make the text as dark or light as you like */
  opacity: 1; /* Required to make the color apply in some browsers */
}

.item-input-theme {
  @apply p-2 rounded-md bg-white dark:text-white dark:bg-gray-700 margin-bottom-small margin-top-medium;
}

.info-container {
  @apply pl-4;  
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
  background-color: rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.info-container.open {
  max-height: 30px; 
}   
.dragging {
  opacity: 0.8;
  cursor: 'grab';
  background-color: #5b647e8a;
}
/* Hide the undo button by default */
.hoverable-row .hover-visible {
  display: none;
}

/* Show the undo button when its parent row is hovered */
.hoverable-row:hover .hover-visible {
  display: inline-block;
}

